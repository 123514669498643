import { isNil } from 'ramda';

export function filterObj<T>(
  fn: (k: string, v: T) => boolean,
  obj: Record<string, T>
) {
  const res: Record<string, T> = {};
  for (const [k, v] of Object.entries(obj)) {
    if (fn(k, v)) {
      res[k] = v;
    }
  }
  return res;
}

export function mapObj<T, Y>(
  fn: (k: string, v: T) => [string, Y],
  obj: Record<string, T>
) {
  const res: Record<string, Y> = {};
  for (const [k, v] of Object.entries(obj)) {
    const [newK, newV] = fn(k, v);
    res[newK] = newV;
  }
  return res;
}

export function filterNilValues<T>(obj: Record<string, T>) {
  const result = filterObj((_k, v) => !isNil(v), obj);
  return result;
}

export function coalesceBlankPropsToNull<T extends Record<string, any>>(
  obj: T
): { [K in keyof T]: T[K] | null } {
  return mapObj((k, v) => [k, v || null], obj) as {
    [K in keyof T]: T[K] | null;
  };
}

export function placeKeyInData<T>(keyName: string, obj: Record<string, T>) {
  if (!keyName || typeof keyName !== 'string') {
    throw new Error('keyName must be provided to placeKeyInData(keyName, obj)');
  }
  return mapObj(
    (key, value) => [
      key,
      {
        ...value,
        [keyName]: key,
      },
    ],
    obj
  );
}

export function listOfKeys<T>(obj: Record<string, T>) {
  return Object.entries(obj)
    .filter(([, v]) => v)
    .map(([k]) => k);
}

export function listOfValues<T>(obj: Record<string, T>) {
  return Object.entries(obj)
    .filter(([, v]) => v)
    .map(([, v]) => v);
}

export * from './check-object-keys';
export * from './enum';
export * from './any-value-defined';
