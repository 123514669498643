import {
  injectOverlayIframe,
  openOverlayIframe,
  removeOverlayIframe,
} from '@purple-dot/libraries/src/iframe-connect/overlay-manager';
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { createURL } from '@purple-dot/libraries/src/url';
import { getPlacementIframes } from '@purple-dot/placement-iframe';
import ShopifyBehaviour from '../../platforms/shopify';
import trackEvent from '../../track-event';

class Checkout {
  constructor(hostURL, apiKey, publicEvents, getCustomerData, locale) {
    if (!(hostURL && apiKey && getCustomerData)) {
      throw new Error('Could not create Checkout');
    }

    this.hostURL = hostURL;
    this.apiKey = apiKey;
    this.getCustomerData = getCustomerData;
    this.publicEvents = publicEvents;
    this.locale = locale;
    this.preorderCreated = false;
    this.listeners = [];

    this._emitPublicEventsFromMessages();
  }

  attachListener(listener) {
    if (!listener?.onCheckoutDismissed) {
      throw new Error('Could not attach listener');
    }
    this.listeners.push(listener);
  }

  open({ checkoutId, cartEnabled }) {
    let checkoutIframe = document.querySelector('#purple-dot-checkout');

    // Checkout ID changed since last time we opened the checkout
    if (checkoutIframe && checkoutIframe.dataset.checkoutId !== checkoutId) {
      removeOverlayIframe('purple-dot-checkout');
      checkoutIframe = null;
    }

    if (checkoutIframe) {
      openOverlayIframe({ id: 'purple-dot-checkout' });

      const ifrms = getPlacementIframes({ placementType: 'button' });
      for (const ifrm of ifrms) {
        ifrm.contentWindow.postMessage(
          {
            type: 'CHECKOUT_SHOWN',
          },
          this.hostURL
        );
      }
    } else {
      injectOverlayIframe({
        hostURL: this.hostURL,
        id: 'purple-dot-checkout',
        src: this._checkoutUrl({ checkoutId, cartEnabled }),
        dataset: {
          checkoutId,
        },
        dataRequestHandlers: {
          CHECKOUT_PREFILL: this.getCustomerData,
          FETCH_CART: fetchCart,
        },
      }).then((child) => {
        trackEvent('placement_loaded', { placementType: 'checkout' });

        const ifrms = getPlacementIframes({ placementType: 'button' });
        for (const ifrm of ifrms) {
          ifrm.contentWindow.postMessage(
            {
              type: 'CHECKOUT_SHOWN',
            },
            this.hostURL
          );
        }

        child.on('navigate-to', ({ url }) => {
          window.location = url;
        });

        child.on('dismiss-iframe', () => {
          if (this.preorderCreated) {
            removeOverlayIframe('purple-dot-checkout');
            this.preorderCreated = false;
          }

          for (const listener of this.listeners) {
            listener.onCheckoutDismissed(this.preorderCreated);
          }
        });
      });
    }
  }

  _emitPublicEventsFromMessages() {
    window.addEventListener('message', (message) => {
      if (message.origin !== this.hostURL) {
        return;
      }

      const { type, ...data } = message.data;
      switch (type) {
        case 'CHECKOUT_LOADED':
          this.publicEvents.checkoutLoaded(data);
          break;

        case 'PREORDER_CHECKOUT_STEP':
          this.publicEvents.preorderCheckoutStep(data);
          break;

        case 'PREORDER_CHECKOUT_SUBMITTED':
          this.publicEvents.preorderCheckoutSubmitted(data);
          break;

        case 'PREORDER_CREATED':
          this.preorderCreated = true;
          this.publicEvents.preorderCreated(data);
          break;

        case 'ORDER_CREATED':
          this.publicEvents.orderCreated(data);
          break;

        case 'PREORDER_FAILED':
          this.publicEvents.preorderFailed(data);
          break;

        case 'REMOVED_FROM_CART':
          this.publicEvents.removeFromCart(data);
          break;

        case 'GO_TO_HOME_PAGE':
          window.location.href = window.Shopify?.routes?.root || '/';
          break;

        default:
          break;
      }
    });
  }

  _checkoutUrl({ checkoutId, cartEnabled }) {
    return createURL({
      host: this.hostURL,
      path: '/embedded-checkout/pre-order-checkout',
      queryParams: {
        checkoutId,
        apiKey: this.apiKey,
        cart: cartEnabled,
        noModal: window.location.search.includes('noModal=true'),
        locale: this.locale,
      },
    });
  }
}

async function fetchCart() {
  return await ShopifyBehaviour.fetchCart();
}

export default Checkout;
