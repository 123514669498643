import { mapObj } from '@purple-dot/libraries/src/language-helpers/objects';
/* eslint-disable no-underscore-dangle */
import { any } from 'ramda';

const Shopify = {
  async fetchCart() {
    const res = await fetch('/cart.js');
    if (res.ok) {
      const cart = await res.json();
      const items = cart.items || [];
      return {
        itemCount: cart.item_count,
        items: items.map((i) => ({
          id: i.id,
          productName: i.product_title,
          properties: i.properties,
          quantity: i.quantity,
        })),
      };
    }
    return null;
  },

  async removeLineItems(externalIds) {
    const cartResponse = await fetch('/cart.js');
    const cart = await cartResponse.json();

    const _externalIds = new Set(
      externalIds.map((externalId) => Number.parseInt(externalId, 10))
    );
    const lineItems = cart.items.filter(({ id }) => _externalIds.has(id));

    const updates = {};
    for (const lineItem of lineItems) {
      updates[lineItem.id] = lineItem.quantity - 1;
    }

    await fetch('/cart/update.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        updates,
      }),
    });
    return true;
  },

  // Shopify hides properties prefixed with a double underscore, which allows us
  // to pass data through without the user seeing it.
  hideProperties(properties) {
    return mapObj((key, val) => [`__${key}`, val], properties);
  },

  cartContainsPreorderItems({ shopifyCart }) {
    const { items } = shopifyCart;

    function isPreorderItem(item) {
      return item.properties?.__releaseId;
    }

    return any(isPreorderItem, items);
  },

  async clearCart() {
    await fetch('/cart/clear.js', { method: 'POST' });
  },
};

export default Shopify;
