import messageHandler from './message-handler';

function ParentToChildBridge({
  hostURL,
  iframeId,
  iframe,
  dataRequestHandlers = {},
}) {
  const listeners = {};
  const postChildMessage = createPostFn({ iframe, hostURL });

  window.addEventListener(
    'message',
    messageHandler('event', ({ meta, data }) => {
      if (meta.sourceId === iframeId) {
        const callbacks = listeners[data.eventName];
        if (callbacks) {
          for (const callback of callbacks) {
            callback(data.eventData);
          }
        }
      }
    })
  );

  window.addEventListener(
    'message',
    messageHandler('data-request', ({ data }) => {
      const handler = dataRequestHandlers[data.key];
      if (handler) {
        Promise.resolve(handler())
          .then((result) => {
            postChildMessage('data-reply', {
              [data.key]: result,
            });
          })
          .catch((err) => {
            // biome-ignore lint/suspicious/noConsole: log
            console.error(err);
          });
      }
    })
  );

  return {
    on: (name, cb) => {
      if (listeners[name]) {
        listeners[name].push(cb);
      } else {
        listeners[name] = [cb];
      }
    },

    get: (key) =>
      new Promise((resolve) => {
        const dataReply = messageHandler('data-reply', ({ data }) => {
          window.removeEventListener('message', dataReply, false);
          resolve(data[key]);
        });

        window.addEventListener('message', dataReply, false);

        postChildMessage('data-request', { key });
      }),
  };
}

function createPostFn({ iframe, hostURL }) {
  return (messageType, data) => {
    iframe.contentWindow.postMessage(
      {
        meta: {
          messageType,
        },
        data: data || {},
      },
      hostURL
    );
  };
}

export default ParentToChildBridge;
