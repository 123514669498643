/* eslint-disable no-underscore-dangle */

function EventsIntegrations(subscribe) {
  return {
    connectFacebookPixel() {
      // https://developers.facebook.com/docs/facebook-pixel/reference

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        if (!window.fbq) {
          return;
        }

        const attrs = {
          currency: event.total.currency,
          value: event.total.amount,
          content_type: 'product',
          content_ids: event.lineItems.map((i) => i.skuId),
        };
        window.fbq('track', 'Purchase', attrs);
        window.fbq('trackCustom', 'Preorder', attrs);
      });

      subscribe('AddToCart', (event) => {
        if (!window.fbq) {
          return;
        }

        const attrs = {
          currency: event.price.currency,
          value: event.price.amount,
          content_type: 'product',
          content_ids: [event.skuId],
        };
        window.fbq('track', 'AddToCart', attrs);
      });
    },

    connectGoogleAdsTag({ sendTo }) {
      // https://developers.google.com/tag-platform/gtagjs/reference/events#purchase

      if (!sendTo) {
        throw new Error('Required argument sendTo not set');
      }

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        if (!window.gtag) {
          return;
        }

        window.gtag('event', 'conversion', {
          send_to: sendTo,
          value: event.total.amount,
          currency: event.total.currency,
          transaction_id: event.reference,
        });
      });

      subscribe('AddToCart', (event) => {
        if (!window.gtag) {
          return;
        }

        window.gtag('event', 'add_to_cart', {
          id: event.skuId,
          currency: event.price.currency,
          price: event.price.amount,
        });
      });
    },

    connectGTMDataLayer() {
      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        if (!window.dataLayer) {
          return;
        }

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: event.reference,
            shipping: event.shipping.amount,
            value: event.total.amount,
            currency: event.total.currency,
            tax: event.tax.amount,
            items: event.lineItems.map((lineItem) => ({
              item_id: lineItem.productId,
              item_name: lineItem.name,
              item_category: undefined,
              coupon: event.discountCode,
              price: lineItem.price.amount,
              quantity: lineItem.quantity,
              item_variant: lineItem.sku,
              purchase_type: lineItem.purchaseType,
            })),
            purchase_type: 'Pre-order',
          },
        });
      });
    },

    enableGoogleTags({ sendTo }) {
      if (!sendTo) {
        throw new Error('Required argument sendTo not set');
      }

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        window.gtag('event', 'purchase', {
          send_to: sendTo,
          transaction_id: event.reference,
          value: event.total.amount,
          currency: event.total.currency,
          tax: event.tax.amount,
          shipping: event.shipping.amount,
          items: event.lineItems.map((lineItem) => ({
            id: lineItem.productId,
            name: lineItem.name,
            category: undefined,
            coupon: event.discountCode,
            price: lineItem.price.amount,
            quantity: lineItem.quantity,
            variant: lineItem.sku,
          })),
          // Custom attribute. To see it, an event-scoped custom dimension has
          // to be created in GA.
          purchase_type: 'Pre-order',
        });
      });
    },

    connectGoogleAnalytics() {
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        if (!window.ga) {
          return;
        }

        for (const lineItem of event.lineItems) {
          window.ga('ec:addProduct', {
            id: lineItem.skuId,
          });
        }
        window.ga('ec:setAction', 'purchase', {
          id: event.reference,
          revenue: event.total.amount,
        });
        window.ga('send', 'event', 'Ecommerce', 'Purchase', {
          nonInteraction: 1,
        });
      });

      subscribe('PreorderCancelled', (event) => {
        if (!window.ga) {
          return;
        }

        window.ga('ec:setAction', 'refund', {
          id: event.preorderReference,
        });
        window.ga('send', 'event', 'Ecommerce', 'Refund', {
          nonInteraction: 1,
        });
      });

      subscribe('AddToCart', (event) => {
        if (!window.ga) {
          return;
        }

        window.ga('ec:addProduct', {
          id: event.skuId,
          price: event.price.amount,
        });
        window.ga('ec:setAction', 'add');
        window.ga('send', 'event', 'UX', 'click', 'add to cart');
      });
    },

    connectTikTokPixel() {
      // https://ads.tiktok.com/gateway/docs/index?identify_key=2b9b4278e47b275f36e7c39a4af4ba067d088e031d5f5fe45d381559ac89ba48&language=ENGLISH&doc_id=1701890973258754

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        if (!window.ttq) {
          return;
        }

        window.ttq.track('PlaceAnOrder', {
          content_type: 'product',
          quantity: event.lineItems.length,
          content_id: event.reference,
          currency: event.total.currency,
          value: event.total.amount,
        });
      });
    },

    connectYotpo() {
      // https://support.yotpo.com/en/article/conversion-tracking

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        const orderId = event.reference;
        const orderNumber = event.reference;
        const orderName = event.reference;
        const orderAmount = event.total.amount;
        const orderCurrency = event.total.currency;

        window.yotpoTrackConversionData = {
          source: 'pixel_v2',
          platform: 'shopify',
          orderId,
          orderNumber,
          orderName,
          orderAmount,
          orderCurrency,
        };
      });
    },

    connectNorthbeamPixel() {
      // https://northbeam-pixel-api.readme.io/recipes/fire-purchase-event

      subscribe(['PreorderCreated', 'OrderCreated'], (event) => {
        function trackPurchaseEvent(event) {
          window.Northbeam.firePurchaseEvent({
            id: event.reference,
            totalPrice: event.total.amount,
            shippingPrice: event.shipping.amount,
            taxPrice: event.tax.amount,
            coupons: event.discountCode,
            currency: event.total.currency,
            customerId: null, // TODO: Pass the Shopify customer ID here
            lineItems: event.lineItems.map((lineItem) => ({
              productId: lineItem.productId,
              variantId: lineItem.skuId,
              productName: lineItem.name,
              variantName: lineItem.sku,
              price: lineItem.price.amount,
              quantity: lineItem.quantity,
            })),
          });
        }

        if (window.Northbeam) {
          trackPurchaseEvent(event);
        } else {
          window.onNorthbeamLoad = () => {
            trackPurchaseEvent(event);
          };
        }
      });
    },
  };
}

export default EventsIntegrations;
